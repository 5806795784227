export class VirtualMachine {
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: number;
  InternalState: number;
  VmId: string;
  Status: string;
  OsName: string;
  OsVersion: string;
  Heartbeat: number;
  Fqdn: string;
  IpAddresses: string;
  MacAddresses: string;
  JobStatus: string;
  StateText: string;
  State: number;
  LicenseState: number;
  Notes: string;
  VisibleNotes: string;
  ProcessorCount: number;
  CpuUsage: number;
  MemoryAssigned: number;
  UptimeTicks: number;
  Uptime: string;
  CreationTime: Date;
  LastUpdated: Date;
  ParentSnapshotId: string;
  MemoryStatus: string;
  MemoryDemand: number;
  ComputerName: string;
  ReplicationHealth: number;
  ReplicationMode: number;
  ReplicationState: number;
  ExtendedReplicationHealth: number;
  ExtendedReplicationState: number;
  TestVmId: string;
  IsClustered: boolean;
  OtherEnabledState: string;
  OperationalStatusText: string;
  Generation: number;
  Version: string;
  IntegrationServicesState: string;
  IntegrationServicesVersion: string;
  ConfigurationLocation: string;
  Path: string;
  DynamicMemoryEnabled: boolean;
  MemoryMinimum: number;
  MemoryMaximum: number;
  IsSaved: boolean;
  IsSaving: boolean;
  IsStopped: boolean;
  IsStopping: boolean;
  IsRunning: boolean;
  IsStarting: boolean;
  IsPaused: boolean;
  IsPausing: boolean;
  IsReplica: boolean;
  IsUnknown: boolean;
  IsStatusCritical: boolean;
  IsReplicaCritical: boolean;
  IsCritical: boolean;
  IsStatusWarning: boolean;
  IsReplicaWarning: boolean;
  IsWarning: boolean;
}

export class Host {
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: number;
  InternalState: number;
  MachineUuid: string;
  MachineId: string;
  State: number;;
  LicenseState: number;
  NumberOfCores: number;
  Domain: string;
  LastUpdated: string;
  CpuUsage: number;
  FreeMemmory: number;
  RdgEnabled: boolean;
  RdgExternalAddress: string;
  ClusterNodeState: number;
  OsName: string;
  OsVersion: string;
  AgentVersion: string;
  NeedUpgrade: boolean;
  IsHostOk: boolean;
  IsClusterNodeOk: boolean;
}

export class Cluster {
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: number;
  InternalState: number;
  ClusterId: string;
  Domain: string;
  SharedVolumesRoot: string
}
export class VirtualNetworkSwitch implements IVirtualSwitch {
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: boolean;
  InternalState: number;
  SwitchId: string;
  SwitchType: number;
  Notes: string;
  AllowManagementOS: boolean;
  NetAdapterInterface: string;
  NetAdapterInterfaceDescription: string;
  ComputerName: string;
  VlanId: number;
  IsSdnSwitch: boolean;
  public static Clone(that: VirtualNetworkSwitch) {
    var res = new VirtualNetworkSwitch();
    res.Id = that.Id;
    res.Name = that.Name;
    res.Description = that.Description;
    res.Parent_Id = that.Parent_Id;
    res.ObjectType = that.ObjectType;
    res.Shared = that.Shared;
    res.InternalState = that.InternalState;
    res.SwitchId = that.SwitchId;
    res.SwitchType = that.SwitchType;
    res.Notes = that.Notes;
    res.AllowManagementOS = that.AllowManagementOS;
    res.NetAdapterInterface = that.NetAdapterInterface;
    res.NetAdapterInterfaceDescription = that.NetAdapterInterfaceDescription;
    res.ComputerName = that.ComputerName;
    res.VlanId = that.VlanId;
    res.IsSdnSwitch = that.IsSdnSwitch;
    return res;
  }
}
export class NetAdapter
{
  Name: string;
  DeviceId: string;
  PermanentAddress: string;
  InterfaceDescription: string;
}

export interface IVirtualSwitch
{
  SwitchId: string;
  SwitchType: number;
  Name: string;
  Notes: string;
  Shared: boolean;
  AllowManagementOS: boolean;
  NetAdapterInterface: string;
  VlanId: number;
}

export enum Operation {
  Unknown = "Unknown",
  Start = "Start",
  TurnOff = "TurnOff",
  Shutdown = "Shutdown",
  Save = "Save",
  Pause = "Pause",
  Resume = "Resume",
  Reset = "Reset",
  Checkpoint = "Checkpoint",
  Remove = "Remove",
  RemoveSavedState = "RemoveSavedState",
  Connect = "Connect",
  Read = "Read",
  Rename = "Rename",
  AttachIso = "AttachIso",
  Export = "Export",
  Move = "Move",
  AddToCluster = "AddToCluster",
  RemoveFromCluster = "RemoveFromCluster",
  Clone = "Clone",
  Backup = "Backup",
  Replicate = "Replicate",
  Template = "Template",
  EditSettings = "EditSettings"
}

export class VMSnapshot {
  Id: string;
  Name: string;
  ParentSnapshotId: string;
  VMId: string;
}

export class EnumDecoder {
  Code: number;
  Name: string;
  public static Inst(code: number, name: string) {
    let res = new EnumDecoder();
    res.Code = code;
    res.Name = name;
    return res;
  }
}

export class Job {
  Id: string;
  Name: string;
  Started: Date;
  Finished: Date;
  LastUpdated: Date;
  Progress: number;
  Status: string;
  CustomData: string;
  JobStatus: number;
  UserId: string;
  UserName: string;
  CanCancel: boolean;
  Plugin: string;
  ContextObjectId: string;
  OperationId: string;
  IsFinished: boolean;

  static jobStatusList: Array<EnumDecoder> = null;

  public static GetJobStatusList() {
    if (Job.jobStatusList == null) {
      let res = new Array<EnumDecoder>();
      res[0] = EnumDecoder.Inst(0, 'Initializing');
      res[1] = EnumDecoder.Inst(1, 'Started');
      res[2] = EnumDecoder.Inst(2, 'Running');
      res[3] = EnumDecoder.Inst(3, 'Completed');
      res[4] = EnumDecoder.Inst(4, 'Failed');
      res[5] = EnumDecoder.Inst(5, 'Canceled');
      res[6] = EnumDecoder.Inst(6, 'Warning');
      Job.jobStatusList = res;
    }
    return Job.jobStatusList;
  }

  public static GetJobStatus(code: number) {
    let list = Job.GetJobStatusList();
    if (code < list.length)
      return list[code].Name;
    else
      return '';
  }
}

export class ContextObject {
  Id: string;
  Name: string;
  ObjectType: "VirtualNetworkSwitch"
  Parent_Id: string;
  Shared: number;
  InternalState: number;
}
export class StorageData {
  Id: string;
  Type: number;
  Path: string;
  ContentType: StorageContentType;
  Capacity: number;
  FreeSpace: number;
  ValidContextObjects: any[];
  ValidCategoryGroup: any[];
  Name: string;
  Description: string;
  ObjectType: string;
  Shared: number;
  InternalState: number;
  CategoryGroupLinks: any[];
}

export class Alarm {
  Id: string;
  Type: number;
  Time: Date;
  Measurement: string;
  Parameter: number;
  Source_Id: string;
  Source: object;
  Info: string;
  RepeatCount: number;
  Resolved: boolean
}
export  enum StorageContentType {
  BackupStorage = "BackupStorage",
  VMStorage = "VMStorage",
  ISOLib = "ISOLib",
  TemplateLib ="TemplateLib"
}
export class CategoryGroup {
  Id: string;
  Name: string;
  Description: string;
  Category_Id: string;
  Category: any;
}
